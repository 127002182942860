import React, { useEffect, useState, useRef } from "react"
import Layout from "../components/layout"
import tw, { styled } from "twin.macro"
import { useShoppingCart } from "use-shopping-cart"
import { CartItems } from "../components/cart"
import Confetti from "react-dom-confetti"
import * as queryString from "query-string"
import { useReactToPrint } from "react-to-print"
import LogRocket from 'logrocket';
LogRocket.init('ziiqiw/studio-conscious');

const Print = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
`

const PrintWrapper = styled.div`
  position: relative;
`

const Header = styled.section`
  ${tw`mt-20 text-center`}
`

const Confirm = styled.div`
  background: #d8d4c9;
  padding: 50px;
  text-align: center;
  .width {
    width: 50%;
    margin: 0 auto;
    @media (max-width: 600px) {
      width: 90%;
    }
  }
`

const Receipt = styled.div`
  h1 {
    font-size: 24px;
  }
  padding: 0px;
  img {
    width: 300px;
  }

  .grid {
    text-align: left;
    margin-top: 50px;
    .name {
      font-weight: 900;
    }
    .title {
      padding-left: 25px;
    }
    display: grid;
    @media (max-width: 1575px) {
      display: block;
      width: 300px;
      margin: 0 auto;
    }
    @media print {
      display: grid;
      width: initial;
      margin-top: 50px;
    }
    grid-template-columns: 300px 300px 1fr;
    grid-row-gap: 25px;
  }
  .summary {
    width: 66%;
    margin: 0 auto;
    text-align: center;
  }
  .order {
    // text-align: left;
    margin-bottom: 50px;
    h1 {
      text-align: center;
    }
    .order-grid {
      display: grid;
      grid-template-columns: 300px 300px 1fr;
    }
  }
`

const Success = ({ location }) => {
  const parsed = queryString.parse(location.search)
  console.log(parsed.session_id)

  let fetchUrl
  if (location.origin === "http://localhost:8888") {
    fetchUrl = `http://localhost:8888/.netlify/functions/success`
  } else if (location.origin === "http://dev.studioconscious.com") {
    fetchUrl = `https://studioconscious-dev.netlify.app/.netlify/functions/success`
  } else {
    fetchUrl = "/.netlify/functions/success"
  }

  var fetchData = async function () {
    const response = await fetch(fetchUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: parsed.session_id,
      }),
    })
      .then(res => {
        return res.json()
      })
      .catch(error => console.log(error))

    console.log("response")
    console.log(response)

    return response
  }

  console.log("fetchData")

  //console.log(fetchData)

  // fetchData.then(test => {
  //   console.log(test)
  // })

  //fetchData().then(value => console.log(value))

  const Test = () => {
    const [data, setData] = useState("")

    useEffect(() => {
      fetchData().then(test => {
        console.log(test)
        console.log("test")
        setData(test)
      })
    }, [])

    var total = data && data.customer_session.amount_total

    total = total / 100

    total = total.toLocaleString("en-US", {
      style: "currency",
      currency: "CAD",
    })

    if (data === undefined){

    }

    let joined =
      data.images1 &&
      data.images1
        .flatMap(a => data.lineItems.data.map(li => ({ a, li })))
        .filter(obj => obj.a.name == obj.li.description)
        .map(obj => {
          var dollars = obj.li.amount_total / 100
          dollars = dollars.toLocaleString("en-US", {
            style: "currency",
            currency: "CAD",
          })
          return {
            image: obj.a.images[0],
            name: obj.a.name,
            price: dollars,
            id: obj.a.id,
          }
        })

    console.log("joined")
    console.log(joined)

    return data ? (
      <div>
        <Confirm className="confirm">
          <h1>Order Confirmation</h1>
          <p>
            Hi {data.customer_session.shipping.name}, thank you for your order!
          </p>
          <p className="width">
            We've received your order and will contact you to arrange pickup as
            soon as your package is ready.
            <br />
            <br /> You can find your purchase information below.
          </p>
        </Confirm>
        <Receipt ref={componentRef}>
          <div className="summary">
            <h1>Order Summary</h1>
            <h2>Payment ID: {data.customer_session.payment_intent}</h2>
            <p>
              Hold on to this payment ID, this allows us to verify your purchase
            </p>
            {/* <p>
            {data.lineItems.data.map(item => {
              return <p>{item.id}</p>
            })}
          </p> */}
            <div className="grid">
              {joined.map(product => {
                return (
                  <>
                    <div>
                      <img src={product.image} />
                    </div>
                    <div className="title">
                      <p className="name">{product.name}</p>
                    </div>
                    <div>
                      <p>{product.id}</p>
                      <p>{product.price}</p>
                    </div>
                  </>
                )
              })}
            </div>
            <div className="order">
              <h1>Order Total</h1>
              <p>Total Price: {total}</p>
              {/* <div className="order-grid">
              <span></span>
              <p>Total Price:</p>
              <p> {total}</p>
            </div> */}
            </div>
            <div className="address">
              <h1>Address</h1>
              <p>At this time we do not offer shipping</p>
              <p>{data.customer_session.shipping.name}</p>
              <p>
                {data.customer_session.shipping.address.line1},{" "}
                {data.customer_session.shipping.address.city}
              </p>
              <p>
                {data.customer_session.shipping.address.postal_code}
                <span>, </span>
                {data.customer_session.shipping.address.state}
              </p>
            </div>
          </div>
        </Receipt>
      </div>
    ) : (
      <p>loading!</p>
    )
  }

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const { clearCart } = useShoppingCart()

  const [explode, setExplode] = useState(false)
  const [grab, setGrab] = useState(false)

  useEffect(() => {
    clearCart()
    setExplode(true)
  })

  const grabDetails = () =>{
    setGrab(true)
  }

  const config = {
    angle: 90,
    spread: 1200,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  }

  return (
    <>
      <Confetti className="test" active={explode} config={config} />
      <Header>
        {/* <h1>Success</h1> */}
        {/* <button onClick={clearCart}>Remove all items</button> */}

        {/* <CartItems /> */}
      </Header>
      <h1>
        Your order has been fulfilled you will recieve a email confirmation.
        You can also click this Grab Details button to view payment ID and other Details.
      </h1>
      <button onClick={grabDetails}>Grab Details</button>
      <PrintWrapper>
        <Print>
          <button onClick={handlePrint}>Print this out!</button>
        </Print>
      </PrintWrapper>
      {grab &&
      
      <Test />
      }
    </>
  )
}

const WithLayout = Component => {
  return props => {
    return (
      <>
        <Layout location={props.location}>
          <Component {...props} />
          {/* <Customer /> */}
          {/* <UserInfo /> */}
        </Layout>
      </>
    )
  }
}

export default WithLayout(Success)
